import React from "react";
import {twMerge} from "tailwind-merge";

export const CompetitionSectionHeader = (
  { icon, title, className = '', subtitle = undefined }:
  { icon: React.ReactNode, title: string, className?: string, subtitle?: string}
) => {
  return (
    <div className={twMerge("font-semibold text-lg sm:text-2xl mt-4 flex flex-row items-center gap-2 w-full my-4", className || '')}>
      {icon} <span>{title}</span>
      <p className="text-neutral-content text-sm">
        {subtitle}
      </p>
    </div>
  );
}