import Link from "next/link";
import {useAccount} from "wagmi";
import React, {useEffect, useRef, useState} from "react";
import {CHAIN_ID, PERPS_VIEW, PERP_MAINNET_LINK, PERP_TESTNET_LINK, OPTIONS_MAINNET_LINK, OPTIONS_TESTNET_LINK} from "@/util/constants";
import {Button, ButtonType} from "@/components/Button";
import {useRouter} from "next/router";
import classNames from "classnames";
import {useWhitelist} from "@/components/whitelist/useWhitelist";
import {Modal, useModal} from "@/components/Modal";
import {disconnect} from "@wagmi/core";
import {SearchBar} from "@/components/SearchBar";
import {UserHeaderButton} from "@/components/Header/UserHeaderButton";
import {ConnectWallet} from "@/components/ConnectWallet";
import {SignAgreement} from "@/components/whitelist/SignAgreement";
import {InfoMessageBanner} from "@/components/Perps/InfoMessageBanner";
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import {AppDropdown} from "@/components/Header/AppDropdown";
import {config} from "../../config";
// Header height is controlled by the theme in tailwind.config.js

interface Link {
  name: string;
  to: string;
  id: number;
  isAuth: boolean;
}

export const Header = () => {
  const isPerpsView = PERPS_VIEW;

  const links: Link[] = isPerpsView
    ? [
      { name: "TRADE", to: "/", id: 100, isAuth: false },
      { name: "COLLECT", to: "/collect", id: 103, isAuth: false },
      { name: "EARN", to: "/vaults", id: 101, isAuth: false },
      { name: "POINTS", to: "/points", id: 102, isAuth: false },
    ] : [
      { name: "TRADE", to: "/collections", id: 5, isAuth: false },
      { name: "EARN", to: "/pool/new", id: 4, isAuth: false },
      { name: "PORTFOLIO", to: "/portfolio", id: 2, isAuth: true },
      ...(CHAIN_ID === 1 ? [] : [{ name: "BUY NFTS", to: "/buyNFTs", id: 3, isAuth: true }]),
    ];

  const { address, chain } = useAccount();
  const router = useRouter();
  const whitelist = useWhitelist();
  const signatureModal = useModal();

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (address && whitelist.data === "NEEDS_TO_SIGN" && !signatureModal.modalVisible) {
      signatureModal.show();
    }
  }, [whitelist.data, address, signatureModal.modalVisible]);

  const onCancel = async () => {
    if (address) {
      await disconnect(config);
    }
    signatureModal.hide();
  }

  const onSuccess = async () => {
    await whitelist.refetch();
    signatureModal.hide();
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const componentRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
   <>
    <InfoMessageBanner/>
    <div className="p-6 flex flex-col lg:flex-row w-full justify-between items-center gap-4">
      <div className="responsive-flex gap-2 md:gap-2">
        <div className="relative" ref={componentRef}>
          {isDropdownOpen && <AppDropdown/>}
        </div>
        <div className="flex flex-row min-w-[160px] hover:cursor-pointer gap-2">
          <Link href="/">
            <img src="/static/wasabi_logo.svg" className="w-[160px] h-[50px]" alt="wasabi"/>
          </Link>
          <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex items-center cursor-pointer">
            {isDropdownOpen ? <FaAngleUp size={22} className="mr-2"/> : <FaAngleDown size={22} className="mr-2"/>}
          </div>
        </div>
        <ul className="flex flex-row items-center gap-2">
          {
            links
                .filter(l => hasMounted && (!l.isAuth || address !== undefined))
                .map(({name, to, id}) => {
                  // const isActive
                  return (
                      <Link key={id}
                            href={to}
                            className={classNames("text-[15px] tracking-widest font-normal navbar_link hover:text-white", {
                              "text-white": to === router.pathname,
                              "text-neutral-content": to !== router.pathname,
                            })}
                            id={`navbar_link_${id}`}>
                        {name}
                      </Link>
                  );
                })}
        </ul>
      </div>
      <div className="flex flex-row items-center justify-center">
        {hasMounted && ((!address || chain?.id !== CHAIN_ID) ?  (
            <>
              {
                  !isPerpsView &&
                  <a className="rounded-md text-neutral-content border border-neutral-content m-1 text-sm">
                    <SearchBar/>
                  </a>
          }
          <ConnectWallet />
        </>
      ) : (
        <UserHeaderButton address={address} />
      ))}
      </div>
      <Modal {...signatureModal} controlled={true}>
        <SignAgreement onCancel={onCancel} onSuccess={onSuccess} />
      </Modal>
    </div>
   </>
  );
};
