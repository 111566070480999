import {ContractInterface} from "ethers";
import {ButtonType} from "@/components/Button";
import {useContext} from "react";
import {UserBalanceContext} from "@/contexts/UserBalanceContext";
import {useHasMounted} from "@/hooks/useHasMounted";
import {
    TransactionButton,
    TransactionButtonProps
} from "@/components/Transactions/TransactionButton";

export const TestNFTMinter = ({
    address, abi, onSuccess = undefined, canReset = false, autoStart = false
}: {
    address: string, abi: ContractInterface, onSuccess?: Function, canReset?: boolean, autoStart?: boolean
}) => {
    const hasMounted = useHasMounted();
    const {refreshBalance} = useContext(UserBalanceContext);
    const txnButtonProps: TransactionButtonProps = {
        id: "testNFTMint",
        contractInterface: abi as any,
        addressOrName: address,
        functionName: "mint",
        onTransactionSuccess: () => {
            refreshBalance?.();
            onSuccess?.();
        }
    }

    if (!hasMounted) {
        return null;
    }

    return (
        <div className="w-full flex flex-col items-center justify-center">
            <TransactionButton {...txnButtonProps} buttonType={ButtonType.SECONDARY}>
                Mint
            </TransactionButton>
        </div>
    );
}