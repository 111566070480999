import React, { FC, useContext } from "react";
import { Theme } from "react-daisyui";
import { Header } from "@/components/Header";
import { ThemeContext } from "@/contexts/ThemeContext";
import classNames from "classnames";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  hideHeader?: boolean;
  skipGeofence?: boolean;
  fixedHeight?: boolean;
  grow?: boolean;
  hidePadding?: boolean;
};

export const PrimaryLayout: FC<Props> = ({ children, hideHeader = false, fixedHeight = false, grow = false, hidePadding = false}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Theme dataTheme={theme}
           className={classNames("flex flex-col", {
             "h-full overflow-y-hidden no-scrollbar": fixedHeight,
           })}
           style={{backgroundColor: 'unset'}}>
      { !hideHeader && <Header /> }
      <div className={classNames("flex flex-col w-full no-scrollbar", {
        "overflow-y-hidden": fixedHeight,
        "flex-grow": grow,
        "sm:p-2": !hidePadding,
      })}>
        {children}
      </div>
    </Theme>
  );
};
